import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  BaseDataTable,
  Form,
  FormSetter,
  FormGetter,
  DateInput,
  AutocompleteInput,
  Grid,
  ConfigurationContext,
} from '@kirz/mui-admin';
import {
  Box,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from 'contexts';
import { api } from 'services/api';

export function LeadsReport() {
  const [rows, setRows] = useState<any[] | null>(null);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const { hasura } = useContext(ConfigurationContext);
  const [isReady, setIsReady] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<string | null>(null);
  const [funnelId, setFunnelId] = useState<number | null>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'Порядковый номер за месяц',
        headerName: 'Порядковый номер за месяц',
        flex: 1,
      },
      {
        field: 'Порядковый номер за неделю',
        headerName: 'Порядковый номер за неделю',
        flex: 1,
      },
      {
        field: 'Дата обращения',
        headerName: 'Дата обращения',
        flex: 1,
      },
      {
        field: 'Источник лида',
        headerName: 'Источник лида',
        flex: 1,
      },
      {
        field: 'Эксперт',
        headerName: 'Эксперт',
        flex: 1,
      },
      {
        field: 'ID объекта',
        headerName: 'ID объекта',
        flex: 1,
        renderCell: ({ row }) =>
          row['ID объекта'] ? (
            <Link href={`/estates/${row['ID объекта']}`}>
              #{row['ID объекта']}
            </Link>
          ) : (
            '—'
          ),
      },
      {
        field: 'Название объекта',
        headerName: 'Название объекта',
        flex: 1,
      },
      {
        field: 'Кол-во комнат',
        headerName: 'Кол-во комнат',
        flex: 1,
      },
      {
        field: 'ID сделки',
        headerName: 'ID сделки',
        flex: 1,
        renderCell: ({ row }) =>
          row['ID сделки'] ? (
            <Link href={`/deals/${row['ID сделки']}`}>#{row['ID сделки']}</Link>
          ) : (
            '—'
          ),
      },
      {
        field: 'ID клиента',
        headerName: 'ID клиента',
        flex: 1,
        renderCell: ({ row }) =>
          row['ID клиента'] ? (
            <Link href={`/deals/${row['ID клиента']}`}>
              #{row['ID клиента']}
            </Link>
          ) : (
            '—'
          ),
      },
      {
        field: 'ФИО клиента',
        headerName: 'ФИО клиента',
        flex: 1,
      },
      {
        field: 'Теги',
        headerName: 'Теги',
        flex: 1,
      },
    ],
    [],
  );

  const fetchRows = async (
    isReady: boolean,
    selectedDate: string,
    selectedDateTo: string,
    funnelId: number,
  ) => {
    if (!isReady) {
      return;
    }

    setRows(null);

    const { data } = await api.client.post(`/reports/view`, {
      type: 'leads',
      parameters: {
        '0_funnel_id': [parseInt(funnelId as any, 10)],
        from_date: selectedDate,
        to_date: selectedDateTo,
        ...(user.companyId === 'b270182b-c08b-4fe4-b374-cd3b54c073bd' && {
          user_ids_exclude: [4],
        }),
      },
    });

    setRows((data as any[]).map((x, idx) => ({ ...x, id: idx })));
  };

  const debouncedFetchRows = useMemo(() => {
    return debounce((isReady, fromDate, toDate, funnelId) => {
      fetchRows(isReady, fromDate, toDate, funnelId);
    }, 300);
  }, []);

  useEffect(() => {
    debouncedFetchRows(isReady, selectedDate, selectedDateTo, funnelId);

    return () => {
      debouncedFetchRows.cancel();
    };
  }, [isReady, selectedDate, selectedDateTo, funnelId, debouncedFetchRows]);

  useEffect(() => {
    (async () => {
      const [item] = await hasura.request({
        type: 'query',
        source: 'funnel',
        selection: 'id',
        where: { isRemoved: { _eq: false }, isDefault: { _eq: true } },
      });

      setFunnelId(item?.id?.toString() ?? null);
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <TablePageLayout title="Список лидов за месяц">
      <Box sx={{ my: 2, mx: 1 }}>
        <Form
          defaultValues={{
            date: dayjs().startOf('month').format('YYYY-MM-DD'),
            toDate: dayjs().endOf('month').format('YYYY-MM-DD'),
            funnelId,
          }}
          dense
          persistStateMode={{ type: 'query', queryPrefix: 'df_' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: -0.5,
              flex: 1,
            }}
          >
            <FormSetter
              render={(setValue) => (
                <FormGetter
                  onChange={(item) => {
                    setSelectedDate(item.date);
                    setSelectedDateTo(item.toDate);
                    setFunnelId(item.funnelId);
                  }}
                  render={(item) => (
                    <>
                      <Grid
                        xs={12}
                        md={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Предыдущий день">
                          <IconButton
                            onClick={() =>
                              setValue(
                                'date',
                                dayjs(item.date)
                                  .add(-1, 'day')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            size="small"
                          >
                            <ChevronLeft
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </Tooltip>
                        <DateInput
                          label="Дата (от)"
                          name="date"
                          grid={false}
                          clearable={false}
                          sx={{ mx: 0.01, flex: 1 }}
                        />
                        <Tooltip title="Следующий день">
                          <IconButton
                            onClick={() =>
                              setValue(
                                'date',
                                dayjs(item.date)
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            size="small"
                          >
                            <ChevronRight
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid
                        xs={12}
                        md={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Предыдущий день">
                          <span>
                            <IconButton
                              disabled={!item.toDate}
                              onClick={() =>
                                setValue(
                                  'toDate',
                                  dayjs(item.toDate)
                                    .add(-1, 'day')
                                    .format('YYYY-MM-DD'),
                                )
                              }
                              sx={{ display: { xs: 'none', md: 'flex' } }}
                              size="small"
                            >
                              <ChevronLeft
                                htmlColor={theme.palette.brand.main}
                                sx={{ opacity: 0.5 }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <DateInput
                          label="Дата (до)"
                          name="toDate"
                          xs={12}
                          grid={false}
                          clearable={false}
                          sx={{ mx: 0.01, flex: 1 }}
                        />
                        <Tooltip title="Следующий день">
                          <span>
                            <IconButton
                              disabled={!item.toDate}
                              onClick={() =>
                                setValue(
                                  'toDate',
                                  dayjs(item.toDate)
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD'),
                                )
                              }
                              sx={{ display: { xs: 'none', md: 'flex' } }}
                              size="small"
                            >
                              <ChevronRight
                                htmlColor={theme.palette.brand.main}
                                sx={{ opacity: 0.5 }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                      <AutocompleteInput
                        xs={12}
                        md={3}
                        name="funnelId"
                        mode="hasura"
                        source="funnel"
                        label="Воронка"
                        selection="id name"
                        itemText="name"
                        itemValue={(x) => x.id.toString()}
                        disableClearable
                      />
                    </>
                  )}
                />
              )}
            />
          </Box>
        </Form>
      </Box>

      <BaseDataTable
        id="leads-report-table"
        deletable={false}
        columns={columns}
        loading={!rows}
        rows={rows ?? []}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        editable={{
          icon: 'eye',
        }}
      />
    </TablePageLayout>
  );
}
