import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  BaseDataTable,
  useNavigate,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { Eye, Play } from 'mdi-material-ui';
import React, { useContext, useMemo } from 'react';

import { Permissions } from 'constants/permissions';
import { UserContext } from 'contexts';

export function Reports() {
  const navigate = useNavigate();
  const { hasPermission } = useContext(UserContext);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: 'execute',
        headerName: '',
        width: 180,
        sortable: false,
        selector: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Button
            endIcon={<Play />}
            href={`/reports/${row.type}`}
            sx={{ mr: 2 }}
          >
            Просмотреть
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <TablePageLayout title="Отчеты">
      <BaseDataTable
        id="reports-table"
        deletable={false}
        columns={columns}
        rows={[
          { id: 0, name: 'Список лидов за месяц', type: 'leads' },
          { id: 1, name: 'Конверсия лидов ОП', type: 'conversions' },
        ]}
        editable={{
          icon: 'eye',
          onEdit: (row) => {
            navigate(`/reports/${row.type}`);
          },
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        onRowDoubleClick={({ row }) => {
          navigate(`/reports/${row.type}`);
        }}
        sx={{ mt: -1 }}
      />
    </TablePageLayout>
  );
}
