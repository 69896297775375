import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  BaseDataTable,
  Form,
  FormSetter,
  FormGetter,
  DateInput,
  AutocompleteInput,
  Grid,
  ConfigurationContext,
} from '@kirz/mui-admin';
import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from 'contexts';
import { api } from 'services/api';

export function ConversionsReport() {
  const [rows, setRows] = useState<any[] | null>(null);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const { hasura } = useContext(ConfigurationContext);
  const [isReady, setIsReady] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<string | null>(null);
  const [funnelIds, setFunnelIds] = useState<number[] | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'incoming_lead_ids',
        headerName: 'Входящий лид',
        flex: 1,
        valueGetter({ row }: { row: any }) {
          return (row.incoming_lead_ids ?? []).filter((x: any) => !!x).length;
        },
        renderCell({ row }: { row: any }) {
          return (
            <DealsCell
              deals={(row.incoming_lead_ids ?? []).filter((x: any) => !!x)}
            />
          );
        },
      },
      {
        field: 'prepayments_ids',
        headerName: 'Предоплата',
        flex: 1,
        valueGetter({ row }: { row: any }) {
          return (row.prepayments_ids ?? []).filter((x: any) => !!x).length;
        },
        renderCell({ row }: { row: any }) {
          return (
            <DealsCell
              deals={(row.prepayments_ids ?? []).filter((x: any) => !!x)}
            />
          );
        },
      },
      {
        field: 'Сделка',
        headerName: 'Сделка',
        flex: 1,
        valueGetter({ row }: { row: any }) {
          return (row.deals_ids ?? []).filter((x: any) => !!x).length;
        },
        renderCell({ row }: { row: any }) {
          return (
            <DealsCell deals={(row.deals_ids ?? []).filter((x: any) => !!x)} />
          );
        },
      },
      {
        field: 'Конверсия звонков в сделку',
        headerName: 'Конверсия звонков в сделку',
        flex: 1,
      },
      {
        field: 'Средний срок выхода на сделку',
        headerName: 'Средний срок выхода на сделку',
        flex: 1,
      },
      {
        field: 'Валовый доход',
        headerName: 'Валовый доход',
        flex: 1,
      },
    ],
    [],
  );

  const fetchRows = async (
    isReady: boolean,
    selectedDate: string,
    selectedDateTo: string,
    funnelIds: string[],
  ) => {
    if (!isReady) {
      return;
    }

    setRows(null);

    const { data } = await api.client.post(`/reports/view`, {
      type: 'conversions',
      parameters: {
        '0_funnel_id': funnelIds,
        date_from: selectedDate,
        date_to: selectedDateTo,
        ...(userId && {
          user_id: userId,
        }),
        ...(user.companyId === 'b270182b-c08b-4fe4-b374-cd3b54c073bd' && {
          user_ids_exclude: [4],
        }),
      },
    });

    setRows((data as any[]).map((x, idx) => ({ ...x, id: idx })));
  };

  const debouncedFetchRows = useMemo(() => {
    return debounce((isReady, fromDate, toDate, funnelIds) => {
      fetchRows(isReady, fromDate, toDate, funnelIds);
    }, 300);
  }, []);

  useEffect(() => {
    debouncedFetchRows(isReady, selectedDate, selectedDateTo, funnelIds);

    return () => {
      debouncedFetchRows.cancel();
    };
  }, [isReady, selectedDate, selectedDateTo, funnelIds, debouncedFetchRows]);

  useEffect(() => {
    (async () => {
      const [item] = await hasura.request({
        type: 'query',
        source: 'funnel',
        selection: 'id',
        where: { isRemoved: { _eq: false }, isDefault: { _eq: true } },
      });

      setFunnelIds(item?.id ? [item?.id] : null);
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <TablePageLayout title="Конверсия лидов">
      <Box sx={{ my: 2, mx: 1 }}>
        <Form
          defaultValues={{
            date: dayjs().startOf('month').format('YYYY-MM-DD'),
            toDate: dayjs().endOf('month').format('YYYY-MM-DD'),
            funnelIds,
            userId,
          }}
          dense
          persistStateMode={{ type: 'query', queryPrefix: 'df_', json: true }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: -0.5,
              flex: 1,
            }}
          >
            <FormSetter
              render={(setValue) => (
                <FormGetter
                  onChange={(item) => {
                    setSelectedDate(item.date);
                    setSelectedDateTo(item.toDate);
                    setFunnelIds(item.funnelIds);
                    setUserId(item.userId);
                  }}
                  render={(item) => (
                    <>
                      <Grid
                        xs={12}
                        md={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Предыдущий день">
                          <IconButton
                            onClick={() =>
                              setValue(
                                'date',
                                dayjs(item.date)
                                  .add(-1, 'day')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            size="small"
                          >
                            <ChevronLeft
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </Tooltip>
                        <DateInput
                          label="Дата (от)"
                          name="date"
                          grid={false}
                          clearable={false}
                          sx={{ mx: 0.01, flex: 1 }}
                        />
                        <Tooltip title="Следующий день">
                          <IconButton
                            onClick={() =>
                              setValue(
                                'date',
                                dayjs(item.date)
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD'),
                              )
                            }
                            size="small"
                          >
                            <ChevronRight
                              htmlColor={theme.palette.brand.main}
                              sx={{ opacity: 0.5 }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid
                        xs={12}
                        md={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Предыдущий день">
                          <span>
                            <IconButton
                              disabled={!item.toDate}
                              onClick={() =>
                                setValue(
                                  'toDate',
                                  dayjs(item.toDate)
                                    .add(-1, 'day')
                                    .format('YYYY-MM-DD'),
                                )
                              }
                              sx={{ display: { xs: 'none', md: 'flex' } }}
                              size="small"
                            >
                              <ChevronLeft
                                htmlColor={theme.palette.brand.main}
                                sx={{ opacity: 0.5 }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <DateInput
                          label="Дата (до)"
                          name="toDate"
                          xs={12}
                          grid={false}
                          clearable={false}
                          sx={{
                            ml: 0.1,
                            display: { xs: 'none', md: 'flex' },
                          }}
                        />
                        <Tooltip title="Следующий день">
                          <span>
                            <IconButton
                              disabled={!item.toDate}
                              onClick={() =>
                                setValue(
                                  'toDate',
                                  dayjs(item.toDate)
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD'),
                                )
                              }
                              sx={{ display: { xs: 'none', md: 'flex' } }}
                              size="small"
                            >
                              <ChevronRight
                                htmlColor={theme.palette.brand.main}
                                sx={{ opacity: 0.5 }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                      <AutocompleteInput
                        xs={12}
                        md={3}
                        name="funnelIds"
                        mode="hasura"
                        source="funnel"
                        label="Воронка"
                        selection="id name"
                        itemText="name"
                        disableClearable
                        multiple
                      />
                      <AutocompleteInput
                        xs={12}
                        md={3}
                        name="userId"
                        mode="hasura"
                        source="user"
                        label="Эксперт"
                        selection="id shortFullName"
                        itemText="shortFullName"
                      />
                    </>
                  )}
                />
              )}
            />
          </Box>
        </Form>
      </Box>

      <BaseDataTable
        id="leads-report-table"
        deletable={false}
        columns={columns}
        loading={!rows}
        rows={rows ?? []}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        editable={{
          icon: 'eye',
        }}
      />
    </TablePageLayout>
  );
}

function DealsCell(props: { deals: number[] }) {
  const { deals } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{ color: 'black' }}>
        {deals.length}
      </Button>
      <Menu
        MenuListProps={{}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '300px',
            width: '20ch',
          },
        }}
      >
        {deals.map((deal) => (
          <MenuItem
            key={deal}
            onClick={handleClose}
            href={`/deals/${deal}`}
            component="a"
          >
            Сделка #{deal}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
