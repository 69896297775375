import {
  ConfigurationContext,
  HasuraQuery,
  LinkBehavior,
  useNavigate,
} from '@kirz/mui-admin';
import { Menu } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Skeleton,
  SxProps,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  ListStatus,
  Trello,
  AccountGroupOutline,
  HomeVariantOutline,
  HomeCityOutline,
  PhoneMessageOutline,
  DatabaseOutline,
  ChartBoxOutline,
  CogOutline,
  AccountCogOutline,
  AccountMultipleOutline,
  TargetAccount,
  FilterCogOutline,
  FunctionVariant,
  HelpCircleOutline,
  Fire,
  FileDocumentMultipleOutline,
  CashMultiple,
  Table,
  HomeClockOutline,
  FileTableOutline,
} from 'mdi-material-ui';
import React, { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { CompanyLogo } from 'components/CompanyLogo';
import { FunnelStageType } from 'constants/funnels';
import { Permissions } from 'constants/permissions';
import { NAVIGATION_DRAWER_WIDTH, TOOLBAR_HEIGHT } from 'constants/theme';
import { UserContext } from 'contexts';

import { Styled } from './NavigationDrawer.styled';

type Props = {
  isDrawerOpened: boolean;
  setIsDrawerOpened: (value: boolean) => void;
};

type NavigationSection = {
  name: string;
  items: {
    name: string;
    icon?: React.ReactNode;
    href: string;
    if?: string | string[];
    slots?: {
      right?: React.ReactNode;
    };
  }[];
};

function Counter(props: {
  color: 'primary' | 'error';
  query: HasuraQuery;
  sx?: SxProps;
}) {
  const { sx, query, color } = props;
  const { hasura } = useContext(ConfigurationContext);
  const [count, setCount] = useState<number | null>(null);

  useEffect(() => {
    const unsubscribe = hasura.subscribe(query, (x) => {
      setCount(x.aggregate.count);
    });

    return unsubscribe;
  }, []);

  return (
    <Chip
      label={count === null ? <Skeleton width={8} /> : count}
      color={color}
      size="small"
      sx={{
        ...sx,
        height: 16,
        fontSize: 10,
      }}
    />
  );
}

export function NavigationDrawer({ isDrawerOpened, setIsDrawerOpened }: Props) {
  const { user, latestViewedReleaseId, latestReleaseId, hasPermission } =
    useContext(UserContext);

  const isClient = hasPermission(Permissions.IS_CLIENT);

  const [isDrawerMiniModeEnabled, setIsDrawerMiniModeEnabled] = useState(
    window.localStorage.getItem('drawer-mine-mode') === 'true',
  );
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const navigate = useNavigate();
  const showAllDeals = hasPermission(Permissions.DEALS_VIEW_ALL);

  const sections = (
    [
      {
        name: 'Основное',
        items: [
          {
            name: 'Задачи',
            href: '/tasks',
            icon: <ListStatus />,
            if: Permissions.TASKS_ENABLED,
            slots: {
              right: !isDrawerMiniModeEnabled && (
                <>
                  <Counter
                    color="error"
                    query={{
                      type: 'subscription',
                      aggregation: true,
                      source: 'task',
                      selection: 'aggregate { count }',
                      where: {
                        isFinished: { _eq: false },
                        userId: { _eq: user.id },
                        date: { _lt: dayjs().format('YYYY-MM-DD') },
                      },
                    }}
                  />
                  <Counter
                    color="primary"
                    sx={{ ml: 0.5 }}
                    query={{
                      type: 'subscription',
                      aggregation: true,
                      source: 'task',
                      selection: 'aggregate { count }',
                      where: {
                        isFinished: { _eq: false },
                        userId: { _eq: user.id },
                        date: { _eq: dayjs().format('YYYY-MM-DD') },
                      },
                    }}
                  />
                </>
              ),
            },
          },
          {
            name: 'Воронки',
            href: '/deals',
            icon: <Trello />,
            if: Permissions.DEALS_ENABLED,
            slots: {
              right: !isDrawerMiniModeEnabled && (
                <>
                  <Counter
                    color="primary"
                    query={{
                      type: 'subscription',
                      aggregation: true,
                      source: 'deal',
                      selection: 'aggregate { count }',
                      where: {
                        funnelStage: {
                          type: {
                            _nin: [
                              FunnelStageType.FAILURE,
                              FunnelStageType.WRONG,
                              FunnelStageType.DEAL,
                            ],
                          },
                        },
                        ...(!showAllDeals && {
                          participants: { userId: { _eq: user.id } },
                        }),
                      },
                    }}
                  />
                </>
              ),
            },
          },
          {
            name: 'Лиды',
            href: '/leads',
            icon: <TargetAccount />,
            if: Permissions.LEADS_ENABLED,
          },
        ],
      },
      {
        name: hasPermission(Permissions.IS_CLIENT)
          ? 'Информация'
          : 'База данных',
        items: [
          {
            name: 'Клиенты',
            href: '/clients',
            icon: <AccountGroupOutline />,
            if: Permissions.CLIENTS_ENABLED,
          },
          ...(isClient
            ? [
                {
                  name: 'Купленные',
                  href: '/bought-estates',
                  icon: <HomeVariantOutline />,
                },
                {
                  name: 'Проданные',
                  href: '/sold-estates',
                  icon: <HomeClockOutline />,
                },
                {
                  name: 'Все сделки',
                  href: '/deals',
                  icon: <Table />,
                },
              ]
            : [
                {
                  name: 'Объекты',
                  href: '/estates',
                  icon: <HomeVariantOutline />,
                  if: [Permissions.ESTATES_ENABLED],
                },
                {
                  name: 'Сделки',
                  href: '/deals-list',
                  icon: <Table />,
                  if: [Permissions.DEALS_ENABLED],
                },
              ]),
          {
            name: 'ЖК',
            href: '/residential-complexes',
            icon: <HomeCityOutline />,
            if: Permissions.RESIDENTIAL_COMPLEXES_ENABLED,
          },
          {
            name: 'Звонки',
            href: '/calls',
            icon: <PhoneMessageOutline />,
            if: Permissions.CALLS_ENABLED,
          },
        ],
      },
      {
        name: 'Анализ',
        items: [
          {
            name: 'Статистика',
            href: '/statistics',
            icon: <ChartBoxOutline />,
            if: Permissions.ANALYZE_STATISTICS,
          },
          {
            name: 'Выборка',
            href: '/queries',
            icon: <DatabaseOutline />,
            if: Permissions.ANALYZE_DATABASE_QUERY,
          },
          ...(!hasPermission(Permissions.IS_CLIENT)
            ? [
                {
                  name: 'Зарплата',
                  href: '/salaries',
                  icon: <CashMultiple />,
                },
              ]
            : []),
          {
            name: 'Отчеты',
            href: '/reports',
            icon: <FileTableOutline />,
            if: Permissions.ANALYZE_DATABASE_QUERY,
          },
        ],
      },
      {
        name: 'Настройки',
        items: [
          {
            name: 'Роли',
            href: '/roles',
            icon: <AccountCogOutline />,
            if: Permissions.SETTINGS_ROLES_FULL,
          },
          {
            name: 'Пользователи',
            href: '/users',
            icon: <AccountMultipleOutline />,
            if: Permissions.SETTINGS_USERS_FULL,
          },
          {
            name: 'Воронки',
            href: '/funnels',
            icon: <FilterCogOutline />,
            if: Permissions.SETTINGS_FUNNELS_FULL,
          },
          {
            name: 'Договоры',
            href: '/documents',
            icon: <FileDocumentMultipleOutline />,
            if: Permissions.SETTINGS_DOCUMENTS_FULL,
          },
          // {
          //   name: 'Формулы',
          //   href: '/formules',
          //   icon: <FunctionVariant />,
          //   if: Permissions.SETTINGS_FORMULES_FULL,
          // },
          {
            name: 'Параметры',
            href: '/settings',
            icon: <CogOutline />,
            if: Permissions.SETTINGS_PARAMETERS_FULL,
          },
          ...(!hasPermission(Permissions.IS_CLIENT)
            ? [
                {
                  name: 'Версия CRM',
                  href: '/version',
                  icon:
                    latestReleaseId !== latestViewedReleaseId ? (
                      <Fire htmlColor="orange" />
                    ) : (
                      <HelpCircleOutline />
                    ),
                },
              ]
            : []),
        ],
      },
    ] as NavigationSection[]
  )
    .map((menuItem) => ({
      ...menuItem,
      items: menuItem.items.filter(
        (x) =>
          !x.if ||
          (typeof x.if === 'string'
            ? hasPermission(x.if)
            : x.if.find((y) => hasPermission(y))),
      ),
    }))
    .filter((x) => x.items.length > 0);

  const content = (miniMode: boolean) => (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          px: miniMode ? 0 : 1.5,
          display: 'flex',
          alignItems: 'center',
          height: TOOLBAR_HEIGHT,
          minHeight: TOOLBAR_HEIGHT,
          ...(miniMode && {
            width: '100%',
            justifyContent: 'center',
            ml: '-2px',
          }),
        }}
      >
        {!miniMode && <CompanyLogo sx={{ width: 46, opacity: 0.8, ml: 0.5 }} />}
        <IconButton
          onClick={() => {
            setIsDrawerMiniModeEnabled(!miniMode);
          }}
          sx={{
            display: { xs: 'none', lg: 'flex' },
            ml: 'auto',
            color: 'rgb(209, 213,219)',
            mr: -1,
            ...(miniMode && {
              ml: 0,
              mr: 0,
            }),
          }}
        >
          {!miniMode ? (
            <ChevronLeftIcon sx={{ opacity: 0.8, fontSize: '2rem' }} />
          ) : (
            <Menu />
          )}
        </IconButton>
      </Box>
      <Styled.Divider sx={{ mt: 0, mb: 0 }} />
      <Box
        component={Scrollbars}
        autoHide
        sx={{
          '& div': {
            display: 'flex',
            flexDirection: 'column',
          },
          '& div:nth-of-type(3) > div': {
            backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
          },
        }}
      >
        {sections.map((section) => (
          <Styled.List
            disablePadding
            key={section.name}
            sx={{
              pb: 1,
              ...(miniMode && {
                pb: 2,
              }),
            }}
            subheader={
              <Styled.ListSubheader disableGutters disableSticky>
                {!miniMode && section.name}
              </Styled.ListSubheader>
            }
          >
            {section.items.map((item) => (
              <Tooltip
                key={item.name}
                title={miniMode ? item.name : ''}
                placement="right"
              >
                <Styled.ListItem
                  disableGutters
                  sx={{
                    ...(miniMode && {
                      pl: 0,
                      m: 0,
                      p: 0,
                      minWidth: 0,
                    }),
                  }}
                >
                  <Styled.Button
                    LinkComponent={LinkBehavior}
                    startIcon={item.icon || <SignalCellularAltRoundedIcon />}
                    href={item.href}
                    onClick={async (e) => {
                      const openInNewTab = e.shiftKey || e.ctrlKey || e.metaKey;
                      if (openInNewTab) {
                        return;
                      }

                      e.preventDefault();

                      navigate(item.href);

                      setIsDrawerOpened(false);
                    }}
                    sx={{
                      paddingLeft: '14px',
                      ...(miniMode && {
                        paddingLeft: '14px',
                        minWidth: 0,
                        px: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiButton-startIcon': {
                          marginRight: 0,
                        },
                      }),
                    }}
                  >
                    {!miniMode && item.name}
                    {item?.slots?.right && (
                      <Box
                        sx={{
                          ml: 'auto',
                          display: 'flex',
                          flexDirection: 'row !important',
                          justifyContent: 'flex-end',
                          pr: 0.5,
                        }}
                      >
                        {item.slots.right}
                      </Box>
                    )}
                  </Styled.Button>
                </Styled.ListItem>
              </Tooltip>
            ))}
          </Styled.List>
        ))}
      </Box>
    </Box>
  );

  useEffect(() => {
    window.localStorage.setItem(
      'drawer-mine-mode',
      isDrawerMiniModeEnabled.toString(),
    );
  }, [isDrawerMiniModeEnabled]);

  return (
    <>
      <Box sx={{ height: '100vh' }} />
      <Drawer
        container={container}
        variant="temporary"
        open={isDrawerOpened}
        onClose={() => setIsDrawerOpened(!isDrawerOpened)}
        anchor={isClient ? 'right' : 'left'}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          className: 'no-scrollbar',
        }}
        sx={{
          zIndex: 1300,
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            backgroundColor: 'brand.main',
            boxSizing: 'border-box',
            width: NAVIGATION_DRAWER_WIDTH,
          },
        }}
      >
        {content(false)}
      </Drawer>
      <Styled.Drawer
        variant="permanent"
        // PaperProps={{
        //   className: 'no-scrollbar',
        // }}
        sx={{
          height: '100vh',
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            backgroundColor: 'brand.main',
            boxSizing: 'border-box',
          },
          ...(isDrawerMiniModeEnabled && {
            width: '49px',
          }),
        }}
        PaperProps={{
          sx: {
            ...(isDrawerMiniModeEnabled && {
              width: '49px !important',
            }),
          },
        }}
        open={!isDrawerMiniModeEnabled}
      >
        {content(isDrawerMiniModeEnabled)}
      </Styled.Drawer>
    </>
  );
}
