import {
  AutocompleteInput,
  CheckboxInput,
  ConditionalInput,
  ConfigurationContext,
  FormGetter,
  FormInput,
  FormPageLayout,
  FormSetter,
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExRef,
  SelectInput,
} from '@kirz/mui-admin';
import Grid from '@mui/material/Unstable_Grid2';
import camelCase from 'lodash.camelcase';
import { ListStatus } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  DatabaseQueryParameterTypeList,
  DatabaseQueryParameterType,
  DatabaseQueryModifierTypeList,
} from 'constants/other';

type DatabaseTable = {
  name: string;
  isView: boolean;
  columns: {
    name: string;
    isGenerated: boolean;
  }[];
};

export function Query() {
  const routeParams = useParams() as Record<string, string>;
  const queryId = parseInt(routeParams.id, 10);
  const parametersTableRef = useRef<DataTableExRef>(null);
  const modifiersTableRef = useRef<DataTableExRef>(null);
  const { rest } = useContext(ConfigurationContext);
  const [databaseTables, setDatabaseTables] = useState<DatabaseTable[]>([]);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
      },
      {
        field: 'key',
        headerName: 'Ключ',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Тип',
        type: 'select',
        items: DatabaseQueryParameterTypeList,
        flex: 1,
      },
    ],
    [],
  );

  const modifiersColumns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'column',
        headerName: 'Столбец',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Тип',
        type: 'select',
        items: DatabaseQueryModifierTypeList,
        flex: 1,
      },
    ],
    [],
  );

  // CREATE TABLE "public"."database_query_modifier" ("column" text NOT NULL, "type" text NOT NULL, "link" text, "query" jsonb, "list_item_text" text, "list_item_link" text);

  useEffect(() => {
    (async () => {
      const {
        data: { database },
      } = await rest.client.get('/database/structure');
      setDatabaseTables(database);
    })();
  }, [rest.client]);

  return (
    <FormPageLayout
      source="databaseQuery"
      breadcrumbs={[
        { text: 'Запросы', icon: ListStatus, href: '/queries' },
        (item) => ({ text: item.name, copyOnClick: true }),
      ]}
      breadcrumbsDeps={[]}
      defaultRoute="/queries"
    >
      <Grid container>
        <FormInput name="name" label="Название" md={6} required />
        <CheckboxInput name="isReport" label="Отчет" md={6} />
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <FormInput
          name="content"
          label="Текст SQL-запроса"
          multiline
          minRows={16}
          md={6}
          required
        />
        <Grid
          container
          xs={12}
          md={6}
          sx={{ flexDirection: 'column', display: 'flex' }}
        >
          <Grid xs={12}>
            <DataTableEx
              id="query-parameters-table"
              ref={parametersTableRef}
              source="databaseQueryParameter"
              columns={columns}
              formTitle={(isNew) =>
                isNew ? 'Добавить параметр' : 'Редактирование параметра'
              }
              inline
              skeletonRowsCount={1}
              title="Параметры"
              editable={{
                onEdit(row) {
                  parametersTableRef.current?.openFormDialog(row);
                },
              }}
              sortBy={{ field: 'key', sort: 'asc' }}
              selectProps={{
                filter: {
                  databaseQueryId: { _eq: queryId },
                },
              }}
              formDialogProps={{
                formSubmitterProps: {
                  preSubmit(item) {
                    return {
                      ...item,
                      databaseQueryId: queryId,
                      multiple: item.multiple || false,
                      required: item.required || false,
                    };
                  },
                  onSubmit() {
                    parametersTableRef.current?.reload();
                  },
                },
              }}
            >
              <FormInput name="name" label="Название" required />
              <FormInput name="key" label="Ключ" required />
              <FormSetter
                render={(setValue) => (
                  <AutocompleteInput
                    name="type"
                    label="Тип"
                    options={DatabaseQueryParameterTypeList}
                    required
                    onChange={(_, type) => {
                      if (
                        type !== DatabaseQueryParameterType.REMOTE_AUTOCOMPLETE
                      ) {
                        setValue('remoteTable', null);
                      }
                    }}
                  />
                )}
              />
              <CheckboxInput
                name="multiple"
                label="Несколько значений"
                md={6}
              />
              <CheckboxInput name="required" label="Обязательный" md={6} />
              <ConditionalInput
                deps={['type']}
                variants={[
                  {
                    if: (y) =>
                      y.type === DatabaseQueryParameterType.REMOTE_AUTOCOMPLETE,
                    input: (
                      <AutocompleteInput
                        label="Таблица"
                        name="remoteTable"
                        options={databaseTables.map((x) => ({
                          text: x.name,
                          value: camelCase(x.name),
                        }))}
                        required
                      />
                    ),
                  },
                ]}
              />
              <FormSetter
                render={(setValue) => (
                  <FormGetter
                    names={['remoteTable']}
                    onChange={(values, oldValues) => {
                      if (
                        values.remoteTable === undefined ||
                        oldValues.remoteTable === undefined
                      ) {
                        return;
                      }

                      if (values.remoteTable !== oldValues.remoteTable) {
                        setValue('remoteItemValueColumn', null);
                        setValue('remoteItemTextColumns', []);
                      }
                    }}
                    render={() => (
                      <ConditionalInput
                        deps={['remoteTable']}
                        variants={[
                          {
                            if: (y) => y.remoteTable != null,
                            input: ({ remoteTable }) => {
                              const tableDef = databaseTables.find(
                                (x) => x.name === remoteTable,
                              );

                              const tableColumns = tableDef?.columns.map(
                                (x) => ({
                                  text: x.name,
                                  value: camelCase(x.name),
                                }),
                              );

                              return (
                                <>
                                  <AutocompleteInput
                                    label="Столбец со значением"
                                    name="remoteItemValueColumn"
                                    options={tableColumns || []}
                                    required
                                  />
                                  <AutocompleteInput
                                    label="Столбцы с текстом"
                                    name="remoteItemTextColumns"
                                    options={tableColumns || []}
                                    required
                                    multiple
                                  />
                                </>
                              );
                            },
                          },
                        ]}
                      />
                    )}
                  />
                )}
              />
            </DataTableEx>
          </Grid>
          <FormGetter
            names={['isReport']}
            render={({ isReport }) =>
              isReport && (
                <>
                  <Grid xs={12}>
                    <DataTableEx
                      id="query-modifiers-table"
                      ref={modifiersTableRef}
                      source="databaseQueryModifier"
                      columns={modifiersColumns}
                      inline
                      formTitle={(isNew) =>
                        isNew
                          ? 'Добавить модификатор'
                          : 'Редактирование модификатора'
                      }
                      skeletonRowsCount={1}
                      title="Модификаторы"
                      editable={{
                        onEdit(row) {
                          modifiersTableRef.current?.openFormDialog(row);
                        },
                      }}
                      sortBy={{ field: 'key', sort: 'asc' }}
                      selectProps={{
                        filter: {
                          databaseQueryId: { _eq: queryId },
                        },
                      }}
                      formDialogProps={{
                        formSubmitterProps: {
                          preSubmit(item) {
                            return {
                              ...item,
                              databaseQueryId: queryId,
                              multiple: item.multiple || false,
                              required: item.required || false,
                            };
                          },
                          onSubmit() {
                            parametersTableRef.current?.reload();
                          },
                        },
                      }}
                    >
                      <FormInput name="column" label="Столбец" required />
                      <SelectInput
                        name="type"
                        label="Тип"
                        items={DatabaseQueryModifierTypeList}
                        required
                      />
                      <ConditionalInput
                        deps={['type']}
                        variants={[
                          {
                            if: (y) => y.type === 'link',
                            input: () => {
                              return (
                                <>
                                  <FormInput
                                    label="Шаблон ссылки"
                                    name="link"
                                    required
                                    placeholder={`/deals/$\{row.id}`}
                                    helperText="'row' -- данные строки"
                                  />
                                </>
                              );
                            },
                          },
                        ]}
                      />
                      <ConditionalInput
                        deps={['type']}
                        variants={[
                          {
                            if: (y) => y.type === 'list',
                            input: () => {
                              return (
                                <>
                                  <FormInput
                                    label="Запрос списка"
                                    name="query"
                                    multiline
                                    minRows={3}
                                    required
                                    helperText="JSON объект с GraphQL-запросом"
                                  />
                                </>
                              );
                            },
                          },
                        ]}
                      />
                      <ConditionalInput
                        deps={['type']}
                        variants={[
                          {
                            if: (y) => y.type === 'list',
                            input: () => {
                              return (
                                <>
                                  <FormInput
                                    label="Шаблон текста элемента списка"
                                    name="listItemText"
                                    required
                                    placeholder={`Сделка #$\{item.id}`}
                                    helperText="'item' -- данные элемента списка; 'row' -- данные строки"
                                  />
                                </>
                              );
                            },
                          },
                        ]}
                      />
                      <ConditionalInput
                        deps={['type']}
                        variants={[
                          {
                            if: (y) => y.type === 'list',
                            input: () => {
                              return (
                                <>
                                  <FormInput
                                    label="Шаблон ссылки элемента списка"
                                    name="listItemLink"
                                    required
                                    placeholder={`/deals/$\{item.id}`}
                                    helperText="'item' -- данные элемента списка; 'row' -- данные строки"
                                  />
                                </>
                              );
                            },
                          },
                        ]}
                      />
                    </DataTableEx>
                  </Grid>
                </>
              )
            }
          />
        </Grid>
      </Grid>
    </FormPageLayout>
  );
}
